
import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, SelectionTool, Viewport } from "@itwin/core-frontend";
import { Point3d } from "@itwin/core-geometry";
import { StagePanelLocation, SyncUiEventDispatcher, WidgetState } from "@itwin/appui-react";
import PinTagClient from "../../api/pinTagClient";
import { DTVActions } from "../../../store/Actions";
import { store } from "../../../store/rootReducer";
import { AnnotationDecorator } from "../../tools/decorators/AnnotationsDecorator";
import { SyncUiEventIds } from "../../../store/redux-types";
//------------------------------------------------------------------------------------
//This replaces Add Defects tool.
export class AddPinAnnotationTool extends PrimitiveTool {
    public static override toolId = "AddDefectsTool";               // An external qualifier the gets passed into App.tool.run();
    public static _currentMarkerPoint: Point3d;                     // Holds the position of the most recent pin defect marker created, used for saving the data in PinPorpertyPopup.tsx
    private static defectDecorator: AnnotationDecorator | undefined;   // Holds the reference to the Decorator that creates and renders the Defect Markers.  

    //--------------------------------------------
    public override run(): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        AddPinAnnotationTool._currentMarkerPoint = new Point3d(0);
        //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);


        AddPinAnnotationTool.defectDecorator = new AnnotationDecorator();

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);

        return Promise.resolve(true);
    }
    //------------------------------------------------------------------------------------
    /* Runs when the tool is rerun after being instanciated once.
     */
    public onRestartTool(): Promise<void> {
        const tool = new AddPinAnnotationTool();

        if (!tool.run())
            this.exitTool();
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnDataButtonDown()
     * The event is loaded in the onDataButtonDown() for this tool,
     * So event will remain isolated to this context only.
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();

        //event.button == 2 signifies the right click on mouse. So this runs on mouseup for right click
        if (event.button == 2) {
            IModelApp.tools.run(SelectionTool.toolId);//run the default tool and stop this Primitive tool.

            for (const dec of IModelApp.viewManager.decorators) {
                if (dec.constructor.name.includes("AnnotationDecorator")) {
                    for (let d = 0; d < AnnotationDecorator.createdDefectStore.length; d++) {
                        if (AnnotationDecorator.createdDefectStore[d].position != AnnotationDecorator.lastCreatedPinDefect?.position) {
                            (dec as AnnotationDecorator).removeDefectMarkerObject(AnnotationDecorator.createdDefectStore[d]!)
                        }
                    }
                    //(dec as AnnotationDecorator).removeDefectMarkerObject(AnnotationDecorator.lastCreatedPinDefect!);
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
                }
            }
            AnnotationDecorator.selectedMarkerJson = undefined;
            //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
        }
    }

    //------------------------------------------------------------------------------------
    /* Delete the tag info from Decorator and APi from data base.
     */
    public static async deleteSelectedTag() : Promise<boolean> {
        //API delete
        let status = await PinTagClient.deleteTag(store.getState().auth.accessTokenStatePrivateAPI.accessToken!, AnnotationDecorator.selectedMarkerJson.tagId);
        if (status != false) { IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Tag Deleted", "", OutputMessageType.Toast)); return true;}
        else {IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Error , Could Not Delete", "", OutputMessageType.Toast)); return false}
    }
    //------------------------------------------------------------------------------------
    /* Refresh marker from view port, including the data for continuity in work flow.
     */
    public static async refreshAllMarkers() {

        //clear decorators 
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("AnnotationDecorator")) {
                (dec as AnnotationDecorator).terminate();
                IModelApp.viewManager.dropDecorator(dec);
            }
        }
        //and recreate.
        let data = await PinTagClient.getPinTags(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
        if (data != undefined) {
            //if decorator exist use the existing to append decorator entities, (failsafe)
            let hasDecorator: boolean = false;
            for (const dec of IModelApp.viewManager.decorators) {
                if (dec.constructor.name.includes("AnnotationDecorator")) {
                    hasDecorator = true;
                    (dec as AnnotationDecorator).terminate();
                    (dec as AnnotationDecorator).loadPinTagMarkers(data);
                    IModelApp.viewManager.selectedView?.invalidateDecorations();
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
                }
            }
            if (!hasDecorator) {
                const dec = new AnnotationDecorator();
                dec.loadPinTagMarkers(data)
                IModelApp.viewManager.addDecorator(dec);
                IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
            }
        }
    }
     //------------------------------------------------------------------------------------
    public static invalidateDecoratorCache() {
        if (AddPinAnnotationTool.defectDecorator != undefined) {
            IModelApp.viewManager.addDecorator(AddPinAnnotationTool.defectDecorator!);
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(AddPinAnnotationTool.defectDecorator!);
        }
    }
    //------------------------------------------------------------------------------------
    /* Runs withing the OnButtonDownEvnt and will create a custom marker at the point returned.
     */ 
    private static addMarkerOnPoint(point: Point3d) {
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("AnnotationDecorator")) {
                isDecoratorExists = true;
                AddPinAnnotationTool.defectDecorator = (dec as AnnotationDecorator);
            }
        }
        //-------------------------------------------
        if (AnnotationDecorator.lastCreatedPinDefect) {
            AddPinAnnotationTool.defectDecorator!.removeDefectMarkerObject(AnnotationDecorator.lastCreatedPinDefect);
        }
        AddPinAnnotationTool.defectDecorator!.addMarkerOnPoint(point);//create the defect marker

        //Add a decorator if does not exist , and if it does invalidate to update scene.
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(AddPinAnnotationTool.defectDecorator!) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(AddPinAnnotationTool.defectDecorator!);
    }
    //------------------------------------------------------------------------------------
    //send event to open modal 
    public static async popupModalPannel(): Promise<EventHandled> {
        // SyncUiEventDispatcher.dispatchSyncUiEvent("pin-selected");
        // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
        // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PinListWidget")?.setWidgetState(WidgetState.Open);
        store.dispatch(DTVActions.setShowPinTags(true));
        store.dispatch(DTVActions. setEditModeFlag(false) )
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    /* Overrides Gets called when the Tool is run() from SampleToolsWidget.tsx.
     * does object click +  surface ray intersection test
     * _ev return data about the intersecting object and point of intersection.
     */
    //------------------------------------------------------------------------------------
    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {

        AnnotationDecorator.selectedMarkerJson = undefined;//clear any reseduals

        let div = document.getElementsByClassName("imodeljs-vp")[0];
        div?.addEventListener('mouseup', this.onMouseClickUp);

        AddPinAnnotationTool._currentMarkerPoint.x = _ev.point.x;
        AddPinAnnotationTool._currentMarkerPoint.y = _ev.point.y;
        AddPinAnnotationTool._currentMarkerPoint.z = _ev.point.z;

        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("AnnotationDecorator")) {
                (dec as AnnotationDecorator).terminate();
                IModelApp.viewManager.dropDecorator(dec);
            }
        }

        AddPinAnnotationTool.popupModalPannel();//send event to open modal 
        AddPinAnnotationTool.addMarkerOnPoint(_ev.point);
        
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        SyncUiEventDispatcher.dispatchSyncUiEvent("imodel-display-toggle");//revert the pannel to default
        // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PinListWidget")?.setWidgetState(WidgetState.Open);
        store.dispatch(DTVActions. setEditModeFlag(false) )
        store.dispatch(DTVActions.setShowPinTags(true));
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        //empty
    }
    //------------------------------------------------------------------------------------
    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();
        return EventHandled.Yes;
    }
    //------------------------------------------------------------------------------------
    public override async onCleanup() {
    }
    //------------------------------------------------------------------------------------
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();
        // Enable AccuSnap so that Decorator can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);//this will enable the intersect pointer that only returns points on existing Reality model
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //------------------------------------------------------------------------------------
    /*
     * Oveerides the isvalid location logic with your own implementation
     * in this case forcing it to always return true.
     */ 
    public override isValidLocation(ev: BeButtonEvent, isButtonEvent: boolean): boolean {
        if (ev && isButtonEvent) {
            //not being used.
        }
        return true;//this is a forced hack to ovveride 
    }
}

