import { Vector3d } from "@itwin/core-geometry";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { StagePanelLocation, WidgetState } from "@itwin/appui-abstract";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import React from "react";

import DefectsClient from "../../api/defects";
import PinTagClient from "../../api/pinTagClient";

import { AddPinAnnotationTool } from "../tools/AddPinAnnotationTool";
import "./List.scss";

//Project Share Deps
import axios from "axios";
import { Logger } from "../../api/logging";
import { ConfigManager } from "../../../config/ConfigManager";
import { DTVActions } from "../../../store/Actions";
import { PropertyTableType, RootState } from "../../../store/States";
import { store } from "../../../store/rootReducer";
import { AnnotationDecorator } from "../../tools/decorators/AnnotationsDecorator";
import ModalDialog from "../TreeVisualizer/dialog/dialog";
import { uploadBlob } from "../../api/upload-blob-api";
import { SyncUiEventIds, licenseProductIds } from "../../../store/redux-types";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";
import { deductLicense } from "../LicenseWorkflow";
import { LabeledInput, LabeledSelect } from "@itwin/itwinui-react";


interface EdProps {
    openOClose: boolean;
}
const toDateInputValue = ()=>{
    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
    let hrs = date.getHours() <10?"0"+date.getHours():date.getHours();
    let mins = date.getMinutes() <10?"0"+date.getMinutes():date.getMinutes();
return `${defaultValue}T${hrs}:${mins}`
    
};
/*
 * Modal/Popup For Pin Annotations
 */ 
export const PinPropertyPopup: React.FC<EdProps> = ({ openOClose }) => {
    //----------------------------------------------------------------------------------------
    //Variables.
    //Hide Show Popup modal
    const [showModal, setShowModal] = React.useState<boolean>(!openOClose);
    const [typeOfAnnotation, setTypeOfAnnotation] = React.useState("Tag");
    //-------UI Defect Annotation inputs
    const [ownerName, setOwnerName] = React.useState("");
    const [inspectorName, setInspectorName] = React.useState("");
    const [height, setHeight] = React.useState("1");
    const [length, setLength] = React.useState("1");
    const [installationDateTime, setInstallationDateTime] = React.useState(toDateInputValue());
    const [inspectionDateTime, setInspectionDateTime] = React.useState(toDateInputValue());
    //-------UI Issue Annotation inputs
    const [tagTitle, setTagTitle] = React.useState("");
    const [tagComment, setTagComment] = React.useState("");

    const [externalLink, setExternalLink] = React.useState("");//latest change . was added as per spec to only keep one External link per tag.
    const [externalLinks, setExternalLinks] = React.useState<any[]>([]);//the any in this case is holding a container {title : , link : } : **Depricated.

    const [linkCounter, setLinkCounter] = React.useState<number>();
    const [projectShareSelectedLinks, setProjectShareSelectedLinks] = React.useState<any[]>([]); //{ title: , link : }

    const [dragDropFiles, setDragDropFiles] = React.useState<any[]>([]);
    const [imageFileStore, setImageFileStore] = React.useState<any[]>([]);
    const [dropdownValuestate,setDropdownValueSet] = React.useState({defectiveStructure:"Shelter",defective_component:"Select",defectType_Pin:"Select",crtically:"Minor"})
    //----------------------------------------------------------------------------------------
    React.useEffect(() => {
        saveButtonEnablements();
    }, [tagComment,tagTitle]);
    //-----------
    React.useEffect(() => {
        SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
            if (args.eventIds.has("open-pin-property-popup")) {
                setShowModal(!showModal);
            }
            //This is the edit button , and if it is presses this should be true.
            //on true preload the modal with the currently selected info from the tag.

            optionHtmlMarkup();//run this function once so that the div get the relevant stated(hidden/show) set on init.
            //defaults------------------
            setTagComment("");
            setLinkCounter(0);
            setProjectShareSelectedLinks([]);
            setExternalLinks([]);
            setDragDropFiles([]);
            setImageFileStore([]);
           let firstName =  store.getState().auth.user?.profile.given_name
           let lastName =  store.getState().auth.user?.profile.family_name
           setOwnerName(`${firstName} ${lastName}`)
           setInspectorName(`${firstName} ${lastName}`)
            //-------------------------
            //Run this on init to set the save button state to disabled.
            let saveBtn = (document.getElementById("save-button") as HTMLInputElement);
            if (saveBtn) {saveBtn.disabled = true;}
            //-------------------------
            //On Modal init , status of these static variables will dictate if its used to create or edit the pin information.
            if ( store.getState().dtvState.applicationState.isEditModeActive == true && AnnotationDecorator.selectedMarkerJson != undefined) {editPinTagOps();}
            //-------------------------
        });
    }, [showModal]);
    /*
     * Edit operation on the Pin tag information
     * it is automatically invoked every time the modal is 
     * requested, 
     * Will run base on if : 
     * SampleToolWidget.isEditModeActive == true
     * AnnotationDecorator.selectedMarkerJson != undefined
     */ 
    const editPinTagOps = () => {
        //Check if the edit mode is active and show the modal with the desired content    
        hideShowPageOptions(false);
        if (AnnotationDecorator.selectedMarkerJson.documentLinks != undefined && AnnotationDecorator.selectedMarkerJson.documentLinks != null && AnnotationDecorator.selectedMarkerJson.documentLinks.length > 0) {
            let l = AnnotationDecorator.selectedMarkerJson.documentLinks.length;
            for (let i = 0; i < AnnotationDecorator.selectedMarkerJson.documentLinks.length; i++) {
                let dat = AnnotationDecorator.selectedMarkerJson.documentLinks[i]
                projectShareSelectedLinks.push(dat);
                setProjectShareSelectedLinks(projectShareSelectedLinks);
            }
        }
        if (AnnotationDecorator.selectedMarkerJson.externalLinks != undefined && AnnotationDecorator.selectedMarkerJson.externalLinks != null && AnnotationDecorator.selectedMarkerJson.externalLinks.length > 0) {
            let l = AnnotationDecorator.selectedMarkerJson.externalLinks.length
            for (let i = 0; i < l; i++) {
                let dat = AnnotationDecorator.selectedMarkerJson.externalLinks[i]
                externalLinks.push(dat);
                setExternalLinks(externalLinks);
            }
        }
        populatePageWithTagData(AnnotationDecorator.selectedMarkerJson);
    }
    //----------------------------------------------------------------------------------------
    /*
     * All elements under the class name psc will be cleared
     * This helps refresh the page with new elements witouth appending
     * the page with duplicates.
     */
    const clearClassElements = async (className: string) => {
        let elements = document.getElementsByClassName(className);
        let idArray: string[] = [];
        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                idArray.push(elements[i].id.toString());
            }
            for (let j = 0; j < idArray.length; j++) {
                clearElement(idArray[j]);
            }
        }
    }
    //-------------------------------------------------------------------------------------
    /*
     * Remove Html element base on Id 
     */ 
    const clearElement = (id) => {
        var elem = document.getElementById(id);
        elem?.parentNode?.removeChild(elem);
    }
    //-------------------------------------------------------------------------------------
    /*
     * Clear redundant markers to show refresed markers.
     */
    const clearOldMarkers = () => {
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("AnnotationDecorator")) {
                for (let d = 0; d < AnnotationDecorator.createdDefectStore.length; d++) {
                    if (AnnotationDecorator.createdDefectStore[d].position != AnnotationDecorator.lastCreatedPinDefect?.position) {
                        (dec as AnnotationDecorator).removeDefectMarkerObject(AnnotationDecorator.createdDefectStore[d]!)
                    }
                }
                //(dec as AnnotationDecorator).removeDefectMarkerObject(AnnotationDecorator.lastCreatedPinDefect!);
                IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
            }
        }
    }
    //----------------------------------------------------------------------------------------
    /* Gets called when the model Popup dialog gets closed.
    */ 
    const closingOps = () => {

        if ( store.getState().dtvState.applicationState.isEditModeActive == false && AnnotationDecorator.selectedMarkerJson == undefined) {
            //clear and drop refrences. Dont need this anymore. 
            //THis also helps improve the wflow from creation to higlight tool.
            //as the higlight button checks if decorator exists and then choses to toggel on or off.
            for (const dec of IModelApp.viewManager.decorators) {
                if (dec.constructor.name.includes("AnnotationDecorator")) {
                    (dec as AnnotationDecorator).removeDefectMarkerObject(AnnotationDecorator.lastCreatedPinDefect!);
                    AnnotationDecorator.lastCreatedPinDefect = undefined;
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
                    (dec as AnnotationDecorator).terminate();
                    IModelApp.viewManager.dropDecorator(dec);
                }
            }

            SyncUiEventDispatcher.dispatchSyncUiEvent("imodel-display-toggle");//revert the pannel to default
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
            AnnotationDecorator.selectedMarkerJson = undefined;
        }

        //if highlight tags was enabled before tag creation , recreate the markers again for continuous workflow.
        // if (SampleToolWidget.showPinTags == true && store.getState().dtvState.applicationState.isEditModeActive == false) {
        if (store.getState().dtvState.applicationState.isEditModeActive == false) {

            //On save find and clear all the markers that were created earlier.
            clearOldMarkers();
            AnnotationDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
          //  AddPinAnnotationTool.refreshAllMarkers();//Refresh all markers including the data after save is pressed, so that the user can continue working with updated data
        }
        else {
            //On save find and clear all the markers that were created earlier.
            clearOldMarkers();
            AnnotationDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
        }
        setShowModal(false);
        store.dispatch(DTVActions. setEditModeFlag(false) )
        store.dispatch(DTVActions.setShowPinTags(false));
        UiFramework.content.dialogs.close("PinPropertyPopup");


    }
   //----------------------------------------------------------------------------------------
    /*
     * copy value to React state 
     */ 
    const tagTitleF = async (event) => {
        if (tagTitle.length < 250) {
            setTagTitle(event.target.value)
        }
        else if (tagComment.length == 250) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Exceeding 250 Character Limit", "", OutputMessageType.Toast));
        }

        saveButtonEnablements();//check if Save button needs to be enabled.
    }
    //---------------
    const issueCommentF = async (event) => {
        if (tagComment.length < 500) {
            setTagComment(event.target.value);
        }
        else if (tagComment.length > 500) {
            setTagComment(event.target.value);
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Exceeding 500 Character Limit", "", OutputMessageType.Toast));
        }
        saveButtonEnablements();//check if Save button needs to be enabled.
    }
    //---------------
    /*
     * on Comment box click 
     */ 
    const onClickComment = async (event) => {
        event.preventDefault();
        setTagComment(event.target.value);

        //saveButtonEnablements();//check if Save button needs to be enabled.

    }
    //----------------------------------------------------------------------------------------
    /*
     * Enable disable save button on condition
     * Check each time it is called
     */ 
    const saveButtonEnablements = async () => {
        let saveBtn = (document.getElementById("save-button") as HTMLInputElement);
        if (tagTitle.length > 1 && tagComment.length > 1) {
            if (saveBtn) {
                saveBtn.disabled = false;
            }
        }
        else if (tagTitle == "" && tagComment == "") {//if cannot find data from elemetn fetch it from the retrieved lined tag information.
            let tag = AnnotationDecorator.selectedMarkerJson;
            if (tag != undefined && tag.tagId != undefined) {
                if (tag.description.length > 2 && tag.title.length > 4) {
                    if (saveBtn) {
                        saveBtn.disabled = false;
                    }
                }
            }
        }
        else {
            if (saveBtn) {
                saveBtn.disabled = true;
            }
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Run this procedure 
     * on save for Tags
     */
    const saveTag = async () => {
        //Delete every time this button is pressed , to clear all remnant markers.
        //For continuity in wflow.
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("AnnotationDecorator")) {
                (dec as AnnotationDecorator).terminate();
                IModelApp.viewManager.dropDecorator(dec);
            }
        }
        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_UnHighlighted);
        // Check if we are saving with editing tool or saving a new tag using Add Pin Annotation
        if (store.getState().dtvState.applicationState.isEditModeActive == false) {//If edit mode is off , then save using post as new data

            //close the modal popup.
            openOClose = !openOClose;
            setShowModal(openOClose);
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Please Wait...", "", OutputMessageType.Toast));
            
            let pos: number[] = []
            pos.push(AddPinAnnotationTool._currentMarkerPoint.x);
            pos.push(AddPinAnnotationTool._currentMarkerPoint.y);
            pos.push(AddPinAnnotationTool._currentMarkerPoint.z);

            //all dragdropped Images will be saved to project share 
            await addDragDroppedFilesToProjectShareList(tagTitle, dragDropFiles);

            let empty: any[] = []
            let extLink = { title: tagTitle, link: externalLink }
            empty.push(extLink);
            setExternalLinks(externalLinks);//Updated spec : only push a single link as we only needs 1 external link per tag now.

            let data: any = {
                "title": tagTitle,
                "position": pos,
                "description": tagComment,
                "documentLinks": projectShareSelectedLinks,//wsgids
                "externalLinks": externalLinks
            }

            if (data.title.length != 0 && data.description != "Add comment here" && data.description.length < 500) {
                const token:any =  getAccessTokenPrivate(store.getState())?.toString();
                let status: any;
                status = await PinTagClient.postTag(token, data);
                if (data.title.length == 0) { IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Title Text Missing , Could Not Save", "", OutputMessageType.Toast)); }
                if (data.description == "Add comment here") { IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Comment Text Missing , Could Not Save", "", OutputMessageType.Toast)); }
                if (data.description.length > 500) { IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Comment Text Exceeds Allowed Limit , Please Edit", "", OutputMessageType.Toast)); }
                store.dispatch(DTVActions.SetPropertyTableLoader(true))

                if (status == "success") {
                    AnnotationDecorator.selectedMarkerJson = data;
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);  //update and open sidePannel for Pin Information

                    //if highlight tags was enabled before tag creation , recreate the markers again for continuous workflow.
                    //Update : this also extends to Normal Pin tag Create Option as well as per updated spec. (Creating a new tag should also show all pin tags on Save.)
                    if (store.getState().dtvState.applicationState.showPinTags) {
                        //On save find and clear all the markers that were created earlier.
                        clearOldMarkers();
                        AnnotationDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
                        let defectsdec = new AnnotationDecorator;
                        await  defectsdec.getPinsAndDefect(true)
                        // AddPinAnnotationTool.refreshAllMarkers();//Refresh all markers including the data after save is pressed, so that the user can continue working with updated data
                        AnnotationDecorator.selectedMarkerJson = undefined;
                    }
                    else {
                        //On save find and clear all the markers that were created earlier.
                        clearOldMarkers();
                        AnnotationDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
                        AnnotationDecorator.selectedMarkerJson = undefined;
                    }
                    if(DigitalTwinViewerApp.checkForLicense)deductLicense(licenseProductIds.InspectionTools);
                }
                else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Error! Stopping Tool, Contact Support", "", OutputMessageType.Alert));
                }
                UiFramework.content.dialogs.close("PinPropertyPopup");
            }
        }
        // if edit is enabled, then save using Put , overwrite the selected Tag info.
        else if (store.getState().dtvState.applicationState.isEditModeActive) {

            //close the modal popup.
            openOClose = !openOClose;
            setShowModal(openOClose);
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Please Wait...", "", OutputMessageType.Toast));

            //all dragdropped Images will be saved to project share 
            await addDragDroppedFilesToProjectShareList(tagTitle, dragDropFiles);

            let pos = AnnotationDecorator.selectedMarkerJson.position;
            let data: any = {
                "tagId": AnnotationDecorator.selectedMarkerJson.tagId,
                "title": tagTitle,
                "position": pos,
                "description": tagComment,
                "documentLinks": projectShareSelectedLinks,//wsgids
                "externalLinks": externalLinks
            }
            //save the edited data.
            const token:any =  getAccessTokenPrivate(store.getState())?.toString();
            let status: any;
            status = await PinTagClient.putTag(token, data);

            //Refresh all markers including the data after save is pressed, so that the user can continue working with updated data
            let defectsdec = new AnnotationDecorator;
            await  defectsdec.getPinsAndDefect(true)
            // AddPinAnnotationTool.refreshAllMarkers();
            store.dispatch(DTVActions.SetPropertyTableLoader(true))
            if (status == "success") {
                AnnotationDecorator.selectedMarkerJson = data;
                // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);

                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Tag Saved", "", OutputMessageType.Toast));
            }
            else { IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Failed , Please Retry", "", OutputMessageType.Toast)); }

            // SampleToolWidget.isEditModeActive = false;
        }
        // if(store.getState().dtvState.applicationState.licenseStates.inspectionLicense.licenseEnabled)SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_Highlighted);
        if(DigitalTwinViewerApp.licenseStates.inspectionLicense.licenseEnabled)SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_Highlighted);
        store.dispatch(DTVActions.setEditModeFlag(false) )
        store.dispatch(DTVActions.setShowPinTags(false));
        UiFramework.content.dialogs.close("PinPropertyPopup");
        store.dispatch(DTVActions.SetPropertyTableLoader(false))
        store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_PINS_ANNOTATION_LIST))
    }

    const getAccessToken = (state: RootState) => {
               
        return state.auth.accessTokenState.accessToken;
      }
      const getAccessTokenPrivate = (state: RootState) => {
               
        return state.auth.accessTokenStatePrivateAPI.accessToken;
      }
    //-------------------------------------------------------------------------------------
    /* Operations to Run when Saving the Pind Defect Data into database.
    */ 
    const saveDefect = async() => {
        //Data to be saved . API implementation pending;
        // let e1 = document.getElementById("pinDefectiveStructure") as HTMLSelectElement;
        let defectiveStructure = dropdownValuestate.defectiveStructure;
        // let e2 = document.getElementById("pinDefectiveComponent") as HTMLSelectElement;
        let defectiveComponent = dropdownValuestate.defective_component;
        // let e3 = document.getElementById("pinDefectType") as HTMLSelectElement;
        let defectType = dropdownValuestate.defectType_Pin;
        // let e4 = document.getElementById("pinCriticality") as HTMLSelectElement;
        let crit =dropdownValuestate.crtically;

        let intervation: string = "";
        if (crit == "Minor") {intervation = "Low";}
        if (crit == "Medium") {intervation = "Medium";}
        if (crit == "Major") { intervation = "High"; }

        let pinPoint = AddPinAnnotationTool._currentMarkerPoint;

        let status: any;
        if (pinPoint != undefined) {

            let data = {//container for saving data to database.
                owner: ownerName,

                criticality: crit,
                intervation: intervation,

                defect: defectType,
                defectiveComponent: defectiveComponent,
                defectiveStructure: defectiveStructure,

                height: parseFloat(height),
                length: parseFloat(length),
                inspector: inspectorName,

                installationDate: installationDateTime,
                inspectionDate: inspectionDateTime,
                pinInformation: [pinPoint.x, pinPoint.y, pinPoint.z],
            }
            const token:any =  getAccessToken(store.getState())?.toString();
            status = await DefectsClient.putPinDefectDataJason(token, data);
        }
        if(status == "Success"){

            //Close the modal on save
            openOClose = !openOClose;
            setShowModal(openOClose)
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Defect successfully created.", "", OutputMessageType.Toast));
        }

        let selectPinData = {//create a container for UI display only info on newly saved pin. 
            criticality: crit ,
            defect: defectType,
            defectiveComponent: defectiveComponent,
            inspectionDate: inspectionDateTime,
            intervation: intervation,
            imageFiles : []
        }
        //On save find and clear all the markers that were created earlier.
        clearOldMarkers();
        if(DigitalTwinViewerApp.checkForLicense)deductLicense(licenseProductIds.InspectionTools);

        AnnotationDecorator.lastCreatedPinDefect = undefined;          //make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
        AnnotationDecorator.selectedMarkerJson = selectPinData;        //copy this data for it to show in the sidePannel
        // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);  //update and open sidePannel for Pin Information
        UiFramework.content.dialogs.close("PinPropertyPopup")
        store.dispatch(DTVActions.setShowPinTags(false))
        let defectsdec = new AnnotationDecorator;
          defectsdec.getPinsAndDefect(true)
    }
    //----------------------------------------------------------------------------------------
    /*
     * Button and Change events on user inputs to the HTML element
    */ 
    const updateOwnerName = async (event) => {
        setOwnerName(event.target.value);
    }
    const updateInspectorName = async (event) => {
        setInspectorName(event.target.value);
    }
    const updateHeight = async (event) => {
        setHeight(event.target.value);
    }
    const updateLength = async (event) => {
        setLength(event.target.value);
    }
    const updateInstallationDateTime = async (event) => {
        setInstallationDateTime(event.target.value);
    }
    const updateInspectionDateTime = async (event) => {
        setInspectionDateTime(event.target.value);
    }
    //----------------------------------------------------------------------------------------
    /*
     * Choice to hide or show the 'Select Options' Dropdown button 
     * Used in the Edit pin Annotation page.
     */ 
    const hideShowPageOptions = async(show : boolean) => {
        let pds = document.getElementById('optionId') as HTMLSelectElement;
        let od = document.getElementById("optionIdType") as HTMLSelectElement;
        if (pds && show == true) {
            pds.style.display = 'block';
        }
        else if (pds && show == false){
            pds.style.display = 'none';
        }
        if (od && show == true) {
            od.style.display = 'block';
        }
        else if (od && show == false) {
            od.style.display = 'none';
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     *  Load up the Edit page with the existing Tag information fetched from 
     *  the backend. This recreates the Modal page for the relavant selected Tag.
     */ 
    const populatePageWithTagData = async (tag: any) => {
        if (tag != undefined && tag.tagId != undefined) {
            setTagComment(tag.description)
            setTagTitle(tag.title);

            appendProjShareSelectedLinks(projectShareSelectedLinks);
            appendExternalLinks(externalLinks);
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Searches and flips the state of option elements in the HTML.
     * this function will run When Options dropdown is pressed
     * will hide and show appropriate div html element pages based on selection
     */
    const optionHtmlMarkup = async () => {

        let pds = document.getElementById('optionId') as HTMLSelectElement;
        let de = document.getElementById('defect-modal-container');
        let ae = document.getElementById("annotation-modal-container");

        let level;

        if (pds) {
            level = pds.options[pds.selectedIndex].text;

            if (ae && de && level == "Defect") {
                de.style.display = 'block';
                ae.style.display = 'none';
            }
            else if (ae && de && level == "Tag") {
                de.style.display = 'none'
                ae.style.display = 'block';
            }
        }
        else if (ae && de){
            de.style.display = 'none';
            ae.style.display = 'block';
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Sets the state of the modal html to show and hide relevant html
     * markups , to simulate separate pages. 
     * When setting tag info || Browsing project share files.
    */ 
    const createProjectShareMarkup = async (flip: boolean) => {
        let pds = document.getElementById('optionId');
        let pdst = document.getElementById('optionIdType');

        let pmic = document.getElementById("projectshare-imagemodal-container");
        let pmc = document.getElementById("projectshare-modal-container");

        let ae = document.getElementById("annotation-modal-container");

        if (pdst && pds && pmc && pmic && ae && flip) {
            pds.style.display = 'none';
            pdst.style.display = 'none';
            pmc.style.display = 'block';
            ae.style.display = 'none';
            pmic.style.display = 'none'
        }
        else if (pdst && pds && pmc && pmic && ae &&flip == false) {
            pmc.style.display = 'none';
            pdst.style.display = 'block';
            ae.style.display = 'block';
            pds.style.display = 'block';
            pmic.style.display = 'none'
        }
        //--
        //If duplicate links are selected , remove duplicates as they are not needed.
        //var uniq = projectShareSelectedLinks.filter((item, index) => { return projectShareSelectedLinks.indexOf(item) === index });..
        let psArry: string[] = [];
        for (let i = 0; i < projectShareSelectedLinks.length; i++) {
            psArry.push(projectShareSelectedLinks[i].title);//create are reference array that holds only titles do comparisons on.
        }
        //filter the reference array with uniques
        psArry = psArry.filter((c, index) => {
            return psArry.indexOf(c) === index;
        });

        let counter : number = 0;
        for (let i = 0; i < psArry.length; i++) {//remove multiple instances of the same title that are compared.
            counter = 0;
            for (let j = 0; j < projectShareSelectedLinks.length; j++) {
                if (psArry[i] == projectShareSelectedLinks[j].title) {
                    counter++;
                    if (counter > 1) {
                        counter = 0;
                        projectShareSelectedLinks.splice(j, 1);
                        j = 0;
                    }
                }
            }
        }
        //--
        //After showing the default screen populate it with selected links for UI display.
        appendProjShareSelectedLinks(projectShareSelectedLinks);
    }
    //----------------------------------------------------------------------------------------
    /*
     * Create project Share Images markup , just change the 
     * button that are shown or hidden.
    */
    const createProjectImagesMarkup = async (flip: boolean) => {

        clearClassElements("piel");

        let pds = document.getElementById('optionId');
        let pdst = document.getElementById('optionIdType');

        let pmic = document.getElementById("projectshare-imagemodal-container");
        let pmc = document.getElementById("projectshare-modal-container");

        let ae = document.getElementById("annotation-modal-container");

        //Show hide relavant elements for intended effect
        if (pdst && pds && pmic && pmc && ae && flip) {
            pds.style.display = 'none';
            pdst.style.display = 'none';
            pmic.style.display = 'block';
            ae.style.display = 'none';
            pmc.style.display = 'none'
        }
        else if (pdst && pds && pmic && ae && pmc && flip == false) {
            pmic.style.display = 'none';
            pdst.style.display = 'block';
            ae.style.display = 'block';
            pds.style.display = 'block';
            pmc.style.display = 'none'
        }
        //--
        //If duplicate links are selected , remove duplicates as they are not needed.
        //var uniq = projectShareSelectedLinks.filter((item, index) => { return projectShareSelectedLinks.indexOf(item) === index });..
        let psArry: string[] = [];
        for (let i = 0; i < projectShareSelectedLinks.length; i++) {
            psArry.push(projectShareSelectedLinks[i].title);//create are reference array that holds only titles do comparisons on.
        }
        //filter the reference array with uniques
        psArry = psArry.filter((c, index) => {
            return psArry.indexOf(c) === index;
        });

        let counter: number = 0;
        for (let i = 0; i < psArry.length; i++) {//remove multiple instances of the same title that are compared.
            counter = 0;
            for (let j = 0; j < projectShareSelectedLinks.length; j++) {
                if (psArry[i] == projectShareSelectedLinks[j].title) {
                    counter++;
                    if (counter > 1) {
                        counter = 0;
                        projectShareSelectedLinks.splice(j, 1);
                        j = 0;
                    }
                }
            }
        }
        //--
        //After showing the default screen populate it with selected links for UI display.
        appendProjShareSelectedLinks(projectShareSelectedLinks);    
    }

    const loadAndShowImagesLinkModal = async () => {
        createProjectImagesMarkup(true);//show the page and hide what not to show, only transition to page elements
        projectShareShowAllImages();//popuplate with te relevant links.
    }
   //-------------
    /*
     * Closing ops for the project share markup
    */
    const exitProjectShareModal = async () => {
        createProjectShareMarkup(false);
        createProjectImagesMarkup(false)
        setImageFileStore([]);
        saveButtonEnablements();
    }

    const getEnvironmentPrefix = () => {
        let prefix = ""
        if(ConfigManager.regionCode == 103)prefix="dev-"
        else if(ConfigManager.regionCode == 102)prefix="qa-"
        return prefix
    }

    const getParentFolderData = async (projectId:string) => {
        try{
            let token :any=  getAccessToken(store.getState())?.toString() ;
            
            let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`);

            const subFoldersFo: any = await axios.get(baseUrl.href,{
                headers:{
                    Authorization:token,
                    Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                         } 

            });
        if(subFoldersFo.status == 200) return (subFoldersFo.data)
        }catch(e){
        Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`,e)
        }
    }

    const getDataInSpecificFolder = async (folderId :string) => {
        try{
            let token :any=  getAccessToken(store.getState())?.toString() ;

            let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${folderId}/list`);

            const subFoldersFo: any = await axios.get(baseUrl.href,{
                headers:{
                    Authorization:token,
                    Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                         } 

            });
        if(subFoldersFo.status == 200)return( subFoldersFo.data)
        }catch(e){
          Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${folderId}/list`,e)
        }
    }

    const toggleOnChildFolder = async (folderId :string,type:string) => {
        let data = await getDataInSpecificFolder(folderId)
        if(type == "folder")projectShareRecursions(data)
        else if (type == "image")recurseThroughAllFileAndFindImages(data)

    }
    //----------------------------------------------------------------------------------------
    /* Queries + Displays recursively the Files and Folders in Project Share 
     * This recursion occurs on events of button clicks with respect wsgId associated to
     * those buttons events. This is the project file explorer.
     */
    //----------------------------------------------------------------------------------------
    const projectShareRecursions = async (data) => {
        try{
        if(data ){

            //This will return the top level of folders from project
            let  subFoldersFi:any = data.items.filter(allFolderData => allFolderData.type == "file") ;

            const subFoldersFo: any = data.items.filter(allFolderData => allFolderData.type == "folder")
            // const queryfi = new ProjectShareFileQuery().inRootFolder(wsgId);
            // const subFoldersFi: ProjectShareFolder[] = await _projectShareClient.getFiles(requestContext, ConfigManager.projectId, queryfi.skip(0));
    
            //If elements exist clear. This refreshes the page and stops duplicate elements appending
            clearClassElements('psc');
            clearClassElements('psc-folder-img');
            clearClassElements('psc-holder');
            //Create elements with Project Share information for display and Browsing
            if (subFoldersFo && subFoldersFo.length !=0 ) {//if can find any Folders.
                var mydiv = document.getElementById("projectshare-modal-container");
                if (mydiv) {
                    for (let it = 0; it < subFoldersFo.length; it++) {//if folders exist show folders
    
                        if (subFoldersFo[it].displayName != undefined) {
    
                            var cd = document.createElement("div");
                            cd.className = "psc-holder";
                            cd.id = "psc-holder_" + it.toString();
    
                            var sp = document.createElement("div");
                            sp.className = "psc-folder-img";
                            sp.id = "psc-folder-img_" + subFoldersFo[it].displayName?.toString().replace(/\s/g, "");
                            cd.appendChild(sp);
    
                            var a = document.createElement('a');
                            var linkText = document.createTextNode(subFoldersFo[it].displayName!);
                            a.appendChild(linkText);
                            a.title = subFoldersFo[it].displayName!;
                            a.className = "psc"
                            a.id = "psb_" + subFoldersFo[it].displayName?.toString().replace(/\s/g, "")
                            a.onclick = function (this) {//create an onclick functonal association with this element.
                                toggleOnChildFolder(subFoldersFo[it].id,"folder");//on the button event pass the relvant wsgID for it to be created and stored in its unique Html element.
                            }
                            cd.appendChild(a);
                            mydiv.appendChild(cd);
                            //
                            var br = document.createElement("br");//Cosmetic new line hack
                            br.className = "psc";
                            br.id = "br_" + subFoldersFo[it].displayName?.toString().replace(/\s/g, "");
                            mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                        }
                    }
                }
            }
            else if (subFoldersFi &&  subFoldersFi.length != 0) {//if can find any files.
                var mydiv = document.getElementById("projectshare-modal-container");
                if (mydiv) {
                    for (let it = 0; it < subFoldersFi.length; it++) {//if Files exists show and create file links.       
                       
                        var cd = document.createElement("div");
                        cd.className = "psc-holder";
                        cd.id = "psc-holder_" + it.toString();
    
                        var sp = document.createElement("div");
                        sp.className = "psc-folder-img";
                        sp.id = "psm_" + subFoldersFi[it].displayName!.toString().replace(/\s/g, "");
                        //---------------
                        let re: any = /(?:\.([^.]+))?$/;//get the string after the . character to get the extension
                        let ext: string = "";
                        ext = re.exec(subFoldersFi[it].displayName)[1];
                        
                        if (ext == "pdf") {
                            sp.className = "psc-folder-imgpdf"
                        }
                        else if (ext == "xml" || ext == "otxml") {
                            sp.className = "psc-folder-imgxml"
                        }
                        else if (ext == "jpeg" || ext == "JPG" || ext == "png" || ext == "PNG") {
                            sp.className = "psc-folder-imgimg";
                        }
                        else if (ext == "zip" || ext == "rar" || ext == "7z") {
                            sp.className = "psc-folder-imgzip";
                        }
                        else {
                          sp.className = "psc-folder-imgany";
                        }
                        //---------------
                        cd.appendChild(sp);
    
                        let l = document.createElement('a');
                        if (subFoldersFi[it].displayName != undefined) {
                            var linkText = document.createTextNode(subFoldersFi[it].displayName! + " ");
                            l.appendChild(linkText);
                            l.title = subFoldersFi[it].displayName!;
                            l.className = "psc";
                            l.id = "psl_" + subFoldersFi[it].displayName?.toString().replace(/\s/g, "");//project share button + name of folder(remove/replace all white spaces)
                        }
                        //these are the final files that are present in the folders so clicking on them addeds them to array for save.
                        l.onclick = function (this) {//create an onclick functonal association with this element.
                            clearElement("psl_" + subFoldersFi[it].displayName!.toString().replace(/\s/g, ""));
                            clearElement("psm_" + subFoldersFi[it].displayName!.toString().replace(/\s/g, ""));
                            var psl = {
                                title: subFoldersFi[it].displayName,
                                link: subFoldersFi[it].id
                            }
                            projectShareSelectedLinks.push(psl);
                        }
                        cd.appendChild(l);
                        mydiv.appendChild(cd);
    
                        var br = document.createElement("br");//Cosmetic new line hack
                        br.className = "psc";
                        br.id = "br_" + subFoldersFi[it].name?.toString().replace(/\s/g, "");//elemenate spaces
                        mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                    }
                }
            }
            else if (subFoldersFi.length == 0 && subFoldersFo.length == 0)//else cant find anything , just show empty text.
            {
                var mydiv = document.getElementById("projectshare-modal-container");
                if (mydiv) {
    
                    var cd = document.createElement("div");
                    cd.className = "psc-holder";
                    cd.id = "psc-holder_"
    
                    var ax = document.createElement("e");//Cosmetic new line hack
                    var linkText = document.createTextNode("Empty");
                    ax.appendChild(linkText);
                    ax.className = "psc";
                    ax.id = "empty";
                    cd.appendChild(ax)
    
                    mydiv.appendChild(cd);
                }
            }
        }   
        }catch(e){Logger.error("error in pinpropertypopup file",e)}
    }
    //----------------------------------------------------------------------------------------
    /*
     * Do these operation when Button to link project share files is pressed.
     * This changes the page and updates its contents with the Project Share Folders/Files.
     */ 
    const linkProjectFilesOnClick = async () => {
        createProjectShareMarkup(true);
       let data = await  getParentFolderData(ConfigManager.projectId!)//Fetech data from Project Share Service
       projectShareRecursions (data)
    }
    //----------------------------------------------------------------------------------------
    /*
     * 
     */ 
    const recurseThroughAllFileAndFindImages = async (data) => {
        if(data ){

            const subFoldersFo = data;
            const subFoldersFi : any = data.items.filter(allFolderData => allFolderData.type == "file")
    
            //If elements exist clear. This refreshes the page and stops duplicate elements appending
            clearClassElements('psc');
    
            //Create elements with Project Share information for display and Browsing    
            if (subFoldersFo &&  subFoldersFo.items.length !=0) {//if can find any Folders.
                for (let it = 0; it < subFoldersFo.items.length; it++) {//if folders exist show folders
                    toggleOnChildFolder(subFoldersFo.items[it].id,"image");//use recursion to iterate through folders
                }
            }
            if (subFoldersFi && subFoldersFi.length != 0) {//if can find any files.
                for (let it = 0; it < subFoldersFi.length; it++) {
                    if (subFoldersFi[it]?.displayName?.match(/\.(jpg|jpeg|png|gif)$/i)) {//if folders are images store there details.
                        imageFileStore.push(subFoldersFi[it]);
                    }
                }
            }  
            setImageFileStore(imageFileStore);
            populateImageElements(imageFileStore);//runs recursively.
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Recursively search for images and show all avaialable 
     * in the Project Share Folders.
     */ 
    const projectShareShowAllImages = async () => {
        clearClassElements("piel");
        let empty: any[] = [];
        setImageFileStore(empty);//clear for redundant info.
       let data = await getParentFolderData(ConfigManager.projectId!)
        recurseThroughAllFileAndFindImages(data);//populate
    }
    //----------------------------------------------------------------------------------------
    /*
     * Creates a list of all images to be selected and linked with relvant tag.
     */ 
    const populateImageElements = async (ims:any[]) => {
        if (ims) {
            clearClassElements("piel");
            var mydiv = document.getElementById("projectshare-imagemodal-container");
            if (mydiv && ims.length > 0) {
                for (let it = 0; it < ims.length; it++) {
                    let sp = ims[it].path!.split('/');
                    sp.splice(0, 1);
                    let path = sp.join("/");
                    var linkText = document.createTextNode(path + ": " + ims[it].displayName! + " ");
                    let l = document.createElement('a');
                    l.appendChild(linkText);
                    l.title = ims[it].displayName!;
                    l.className = "piel";
                    l.id = "piel_" + ims[it].path! + ims[it].displayName?.toString().replace(/\s/g, "");//project share button + name of folder(remove/replace all white spaces)
                    //these are the final files that are present in the folders so clicking on them addeds them to array for save.
                    l.onclick = function (this) {//create an onclick functonal association with this element.
                        clearElement("piel_" + ims[it].path! + ims[it].displayName?.toString().replace(/\s/g, ""));
                        var psl = {
                            title: ims[it].displayName,
                            link: ims[it].id
                        }
                        projectShareSelectedLinks.push(psl);
                    }
                    mydiv.appendChild(l);

                    var br = document.createElement("br");//Cosmetic new line hack
                    br.className = "piel";
                    br.id = "br_" + ims[it].displayName?.toString().replace(/\s/g, "");
                    mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                }
            }
        }
    }

    //----------------------------------------------------------------------------------------
    /*
     * Appends the links from the Project Share selections.
     * this will show on the main tag modal page
     */
    const appendProjShareSelectedLinks = async (links: any[]) => {//Aditya
        var mydiv = document.getElementById("holder");//append below this element document.getElementById("holder");
        if (mydiv) {
            clearClassElements('pscl');
            //create new input boxes for links with unique IDs that increment by 1.
            //these ids will be used to interate and get the data out  
            if (links != undefined && links.length != 0) {
                for (let p = 0; p < links.length; p++) {
                    //Create element
                    let l = document.createElement('a');
                    var linkText = document.createTextNode(links[p].title);
                    l.appendChild(linkText);
                    l.title = links[p].link;
                    l.className = "pscl";
                    l.id = "pscl_" + links[p].title.toString().replace(/\[/g, "");//project share button + name of folder(remove/replace all white spaces)
                    l.onclick = function (this) {
                        //moved to button on click below.
                    }

                    l.style.float = "left";
                    //
                    let b = document.createElement('button');
                    b.className = "pscl";
                    b.id = "pscl-button";
                    b.title = "Click To Remove"
                    b.onclick = function (this) {
                        //remove the index that is clicked on from array
                        links = projectShareSelectedLinks.filter(e => e == links[p]);//get the relevant object
                        let index = projectShareSelectedLinks.indexOf(links[0]);//get the index
                        projectShareSelectedLinks.splice(index, 1);
                        setProjectShareSelectedLinks(projectShareSelectedLinks);//clear the element for wich the adj (-) button was pressed

                        if (projectShareSelectedLinks.length == 0) {//this is a necessary hack , the last index does not clear itself from the react usestate element.
                            setProjectShareSelectedLinks([]);//force clear.
                        }
                        appendProjShareSelectedLinks(projectShareSelectedLinks);//update again with final info
                        saveButtonEnablements(); //check state if stve button should be enabled.
                    }
                    l.appendChild(b);
                    //
                    mydiv.appendChild(l);
                    //Create on newline
                    var br = document.createElement("br");//Cosmetic new line workaround
                    br.className = "pscl";
                    br.id = "br_" + links[p].toString().replace(/\s/g, "");
                    mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                }
            }
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Appends the links from the External link adding option fields.
     * this will show on the main tag modal page.
     * 
     * This now works after the popup for input of external link comes up
     */
    const appendExternalLinks = async (links: any[]) => {
        var mydiv = document.getElementById("projShareOptions");
        if (mydiv) {
            clearClassElements('elc');//clear to not append duplicate elements/refresh page element.

            //create new input boxes for links with unique IDs that increment by 1.
            //these ids will be used to interate and get the data out  
            if (links != undefined) {
                for (let p = 0; p < links.length; p++) {
                    //Create element
                    let l = document.createElement('a');
                    var linkText = document.createTextNode(links[p].title);
                    l.appendChild(linkText);
                    l.title = links[p].link;
                    l.className = "elc";
                    l.id = "elc_" + links[p].title.toString();
                    l.onclick = function (this) {//create an onclick functonal association with this element.
                        let index = externalLinks.indexOf(links[p]);
                        setExternalLinks(externalLinks.splice(index, 1));//clear the element for wich the adj (-) button was pressed
                        if (externalLinks.length == 0) {//this is a necessary hack , the last index does not clear it self from the react element.
                            [([])];//force clear.
                        }
                        appendExternalLinks(externalLinks);//update the element with the new info.
                    }
                    mydiv.appendChild(l);
                    //Create on newline
                    var br = document.createElement("br");//Cosmetic new line workaround
                    br.className = "elc";
                    br.id = "br_" + links[p].toString().replace(/\s/g, "");
                    mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                }
            }
        }
    }

    const isValidHttpUrl = async (s:string) =>  {
        let url;
        try {
            url = new window.URL(s);
        } catch (_) {
            return false;
        }
        return url.protocol === "https:";
    }
   //----------------------------------------------------------------------------------------
    /*
     * Event triggerd on Add External Link button.
     */ 
    const openExternalLinkPopup = async () => {
        let text;
        let p = prompt("Input External Link:", "https://");

        if (p == null || p == "") {
            text = "User cancelled the prompt.";
        } else {
            text = p;
            if (text != "") {
                //check if the link has https in it.
                if ((await isValidHttpUrl(text)) == true /*text.indexOf("https://") == 0*//*text.indexOf("https://") == 0*/) {
                    setExternalLinks([]);
                    let elink = { title: "Link", link: text };
                    let tarr: any[] = []
                    tarr.push(elink);
                    setExternalLinks(tarr);//just for display
                    setExternalLink(text);//used during save
                    appendExternalLinks(tarr);//Append links to the page.

                    
                }
                else if (text.indexOf("http://") == 0) {//check state of the URL and diplay messages accordingly. Will only allow https:// links
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Please Input A Secure URL (https://)", "", OutputMessageType.Toast));
                }
                else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Please Input A Valid URL", "", OutputMessageType.Toast));
                }
            }
            else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No URL Input", "", OutputMessageType.Toast));
            }
        }
    }
    //----------------------------------------------------------------------------------------
    /*
     * Searches and flips the state of option elements in the HTML.
     * this function will run When defective Structure dropdown is pressed
     * will filter and show appropriate option for prior selections.
     */ 
    const pinDSSort = async (event) => {

        let defective_component = document.querySelector('.defective_component') as HTMLSelectElement;
        let pds = document.getElementById('pinDefectiveStructure') as HTMLSelectElement;

        if (pds && defective_component) {
            let level = pds.options[pds.selectedIndex].text;

            defective_component.querySelectorAll('option[value]').forEach(function (option) {
                (option as HTMLSelectElement).hidden = !(level === (option as HTMLSelectElement).dataset.level)
            });
            // Sets the first one (Choose Grade Level) selected
            defective_component.querySelector('option')!.selected = true
        }
        event.preventDefault();
    }
    //----------------------------------------------------------------------------------------
    /*
    * Searches and flips the state of option elements in the HTML.
    * this function will run When defective Structure dropdown is pressed
    * will filter and show appropriate option for prior selections.
    */
    const pinDCSort = async (event) => {
        let defective_component = document.querySelector('.defectType_Pin') as HTMLSelectElement;
        let pds = document.getElementById('pinDefectiveComponent') as HTMLSelectElement;

        if (pds && defective_component) {
            let level = pds.options[pds.selectedIndex].text;

            defective_component.querySelectorAll('option[value]').forEach(function (option) {
                (option as HTMLSelectElement).hidden = !(level === (option as HTMLSelectElement).dataset.level)
            });
            // Sets the first one (Choose Grade Level) selected
            defective_component.querySelector('option')!.selected = true
        }
        event.preventDefault();
    }
    //----------------------------------------------------------------------------------------
    /**
     * Runs on Save and store all saved Dragdropped images to Project share
     * image folder. Create a uniqe folder with the Tag title as the folder name.
     */
    const addDragDroppedFilesToProjectShareList = async (folderName: string, dragDroppedFiles: any[]) => {

        if (dragDroppedFiles && dragDroppedFiles.length > 0) {
            //Project access token

            //This will return the top level of folders from project
            // let subFoldersFo;
            const subFoldersFo: any = await getParentFolderData(ConfigManager.projectId!);
            let imWsgid: string = "";
            if (subFoldersFo && subFoldersFo.hasOwnProperty("items") ) {//if can find any Folders.
                for (let it = 0; it < subFoldersFo.items.length; it++) {//if folders exist show folders
                    if (subFoldersFo.items[it].displayName == "Images") {
                        imWsgid = subFoldersFo.items[it].id;
                    }
                }
            }

            // let pf: ProjectShareFolder = new ProjectShareFolder();
            let pf:any = {};
            pf.name = folderName;
            let token :any=  getAccessToken(store.getState())?.toString() ;
            let header = {
                Authorization: token,
                "Content-Type": "application/json",
                Accept: "application/vnd.bentley.itwin-platform.v1+json"
            }
            const newFolder : any = await axios.post(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${imWsgid}/folders`, JSON.stringify({
                "displayName": folderName,
                "description": tagComment
            }),{
                headers:header
            });
            if (newFolder && newFolder.status==201) {
                for (var i = 0; i < dragDroppedFiles.length; i++) {
                    let pff: any = {};//new ProjectShareFile();
                    pff.name = dragDroppedFiles[i].name;
                    pff.contentType = dragDroppedFiles[i].type;
                    pff.size = dragDroppedFiles[i].size;
                    // let s, changedFile;
                    let createFile : any =  await axios.post(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${newFolder.data.folder.id}/files`, JSON.stringify({
                        "displayName":  pff.name,
                        "description":  ""
                    }),{
                        headers:header
                    });
                    if(createFile && createFile.status == 202){
                        const res = await uploadBlob(createFile.data._links.uploadUrl.href, dragDroppedFiles[i]);
                        
                        if(res != undefined){
                            const body: any = {}
                           let res =  await axios.post(createFile.data._links.completeUrl.href,body,{
                                headers:{
                                        Authorization:token,     
                                        "Content-Type": "application/json",
                                        Accept: "application/vnd.bentley.itwin-platform.v1+json"                                        
                                }
                            });
                        if(res && res.status == 200){
                              // Add the uploaded file to the Project file links available in the pin tag.
                            var psl = {
                                        title: res.data.file.displayName,
                                        link: res.data.file.id
                                    }
                                    projectShareSelectedLinks.push(psl);   
                        }

                        } 
                        
                    }
                    // Add the uploaded file to the Project file links available in the pin tag.
                    // if (changedFile) {
                    //     var psl = {
                    //         title: changedFile.name,
                    //         link: changedFile.wsgId
                    //     }
                    //     projectShareSelectedLinks.push(psl);                    
                    // }
                }
            }
        }
        return;
    }
    //----------------------------------------------------------------------------------------
    /*
     * Create the Relvant buttons for the draggdropped images as clicbale elements 
     * for UI content display.
     */ 
    const appendDragDropImageFiles = async (dragDroppedFiles: any[]) => {

        var mydiv = document.getElementById("holder");//append below this element document.getElementById("holder");
        if (mydiv) {
            clearClassElements("ddfl");
            //create new input boxes for links with unique IDs that increment by 1.
            //these ids will be used to interate and get the data out  
            if (dragDroppedFiles != undefined && dragDroppedFiles.length != 0) {
                for (let p = 0; p < dragDroppedFiles.length; p++) {
                    //Create element
                    let l = document.createElement('a');
                    var linkText = document.createTextNode(dragDroppedFiles[p].name);
                    l.appendChild(linkText);
                    l.title = dragDroppedFiles[p].name;
                    l.className = "ddfl";
                    l.id = "ddfl_" + p;
                    l.onclick = function (this) {
                        if (dragDropFiles && dragDropFiles.length > 0) {
                            for (var j = 0; j < dragDropFiles.length; j++) {
                                if ((this as HTMLInputElement).title == dragDropFiles[j].name) {
                                    dragDropFiles.splice(j, 1);
                                    setDragDropFiles(dragDroppedFiles);
                                    appendDragDropImageFiles(dragDropFiles);
                                }
                            }
                        }
                        else {
                            setDragDropFiles([]);
                            appendDragDropImageFiles(dragDropFiles);
                        }
                    }
                    //
                    let b = document.createElement('button');
                    b.className = "ddfl";
                    b.id = "ddfl-button";
                    b.title = "Click To Remove"
                    b.onclick = function (this) {
                        if (dragDropFiles && dragDropFiles.length > 0) {
                            for (var j = 0; j < dragDropFiles.length; j++) {
                                if ((this as HTMLInputElement).title == dragDropFiles[j].name) {
                                    dragDropFiles.splice(j, 1);
                                    setDragDropFiles(dragDroppedFiles);
                                    appendDragDropImageFiles(dragDropFiles);
                                }
                            }
                        }
                        else {
                            setDragDropFiles([]);
                            appendDragDropImageFiles(dragDropFiles);
                        }
                    }
                    l.appendChild(b);
                    //
                    mydiv.appendChild(l);
                    //Create on newline
                    var br = document.createElement("br");//Cosmetic new line workaround
                    br.className = "ddfl";
                    br.id = "ddfbr_" + p;
                    mydiv.appendChild(br);//moves the links to new lines instead of one after the other.
                }
            }
        }
    }
    /*
     * Gets called when images are dragged and dropped in the
     * relavant element container
     */ 
    const readfiles = async (files) => {
        for (var i = 0; i < files.length; i++) {
            //Check if file is of a supported format.
            if (files[i].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                let isExists: boolean = false;//if file already exists dont push duplicates
                for (var j = 0; j < dragDropFiles.length; j++) {
                    if (files[i].name == dragDropFiles[j].name) {
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, files[i].name + ', Already Exists', "", OutputMessageType.Toast));
                        isExists = true;
                    }
                }
                if (!isExists) {//if does not exist add to list.
                    dragDropFiles.push(files[i]);
                }
            }
            else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, files[i].name + ', is Not A Supported Format Image', "", OutputMessageType.Toast));
            }
            setDragDropFiles(dragDropFiles);//append the files added to list.
            appendDragDropImageFiles(dragDropFiles);//create the relavant elements to display
        }
    }
    //On Drop of files over the relevant element area.
    const onImageDrop = async (e) => {
        //var holder = document.getElementById('holder') as HTMLInputElement;
        e.preventDefault();
        //holder.className = '';//disable effect
        readfiles(e.dataTransfer?.files);
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Images Added to List", "", OutputMessageType.Toast));
        saveButtonEnablements();
    }
    //Not in use , use to implement style change on when files are draged over respective element area. 
    const onImageDragOver = async (e) => {
    //    var holder = document.getElementById('holder') as HTMLInputElement;
    //    if (holder) {
            e.preventDefault();
    //        holder.className = 'hover';//for effects
    //    }
    }

    const checkDisabled = () => {
        let check = false;
        let wsRegex = /^\s+|\s+$/g; 
        let owner = ownerName.replace(wsRegex, "");
        let inspector_Name = inspectorName.replace(wsRegex, "");
        let defect_height = height.replace(wsRegex, "");
        let defect_length = length.replace(wsRegex, "");
        let installation_DateTime = installationDateTime.replace(wsRegex, "");
        let inspection_DateTime = inspectionDateTime.replace(wsRegex, "");
        if(owner.length == 0)check = true
        if(inspector_Name.length == 0)check = true
        if(defect_height.length == 0)check = true
        if(defect_length.length == 0)check = true
        if(installation_DateTime.length == 0)check = true
        if(inspection_DateTime.length == 0)check = true
        if(owner.length == 0)check = true
        if(dropdownValuestate.defectType_Pin == "Select")check = true
        if(dropdownValuestate.defectiveStructure == "Select")check = true
        if(dropdownValuestate.defective_component == "Select")check = true
        return check;
    }
    const handleSelectValue = (value,name) => {
        if(name == "defectiveStructure")setDropdownValueSet({defectiveStructure:value,defective_component:"Select",defectType_Pin:"Select",crtically:"Minor"})
       else setDropdownValueSet({...dropdownValuestate,[name]:value})
    }
    

    const selectDefectiveComponent = () => {
        let filteredArray =  [
          
           {
           defectiveStructure:"Shelter",value:"Cabinet",text:"Cabinet"
       },
       {
           defectiveStructure:"Shelter",value:"Door",text:"Door"
       },
       {
           defectiveStructure:"Shelter",value:"Fire Extinguisher",text:"Fire Extinguisher"
       },
       {
           defectiveStructure:"Site Area",value:"Cabinet",text:"Cabinet"
       },
       {
           defectiveStructure:"Site Area",value:"Security Signaling Elements",text:"Security Signaling Elements"
       },
       {
           defectiveStructure:"Earthing Connector",value:"Earthing Connector",text:"Earthing Connector"
       },
       {
           defectiveStructure:"Tower",value:"Elements of union of tower to Earthing",text:"Elements of union of tower to Earthing"
       },
       {
           defectiveStructure:"Tower",value:"Antenna",text:"Antenna"
       },
       {
           defectiveStructure:"Tower",value:"Aeronautical Beacon",text:"Aeronautical Beacon"
       },
       {
           defectiveStructure:"Tower",value:"Pipe",text:"Pipe"
       },
       {
           defectiveStructure:"Tower",value:"Tower Structure",text:"Tower Structure"
       },
       {
           defectiveStructure:"Tower",value:"Foundations",text:"Foundations"
       },
       {
           defectiveStructure:"Tower",value:"Welds Between the Elements of the Structure",text:"Welds Between the Elements of the Structure"
       },
       {
           defectiveStructure:"Tower",value:"Beams",text:"Beams"
       },
       {
           defectiveStructure:"Tower",value:"Anchors joints with Tower Structure",text:"Anchors joints with Tower Structure"
       },

           
       ].filter(defect => defect.defectiveStructure == dropdownValuestate.defectiveStructure)
let arr =  [  {
    defectiveStructure:"Select",value:"Select",text:"Select"
},...filteredArray].map(defect=>{
    let temp =  {label:defect.text,value:defect.value}
    return temp
})
       return  arr;
    }

    const selectDefect = () => {
        let filteredArray =  [
            {
             defectiveComponent:"Cabinet",value:"Paint Damaged",text:"Paint Damaged"
         },{
             defectiveComponent:"Cabinet",value:"Rusty",text:"Rusty"
         },{
             defectiveComponent:"Cabinet",value:"Crack",text:"Crack"
         },{
             defectiveComponent:"Cabinet",value:"Durty",text:"Durty"
         },
             {
                 defectiveComponent:"Door",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Door",value:"Missing",text:"Missing"
             },{
                 defectiveComponent:"Fire Extinguisher",value:"Expired",text:"Expired"
             },{
                 defectiveComponent:"Security Signaling Elements",value:"Unreadable",text:"Unreadable"
             },{
                 defectiveComponent:"Security Signaling Elements",value:"Missing",text:"Missing"
             },{
                 defectiveComponent:"Security Signaling Elements",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Site Area",value:"Vegetation",text:"Vegetation"
             },{
                 defectiveComponent:"Site Area",value:"Paint Damaged",text:"Paint Damaged"
             },{
                 defectiveComponent:"Working Platform",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Brackets",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Earthing Connector",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Earthing Connector",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Elements of union of tower to Earthing",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Lightning Rod Connection",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Lightning Rod Connection",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Antenna",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Antenna",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Antenna",value:"Antenna Encapsulation Deteriorated",text:"Antenna Encapsulation Deteriorated"
             },{
                 defectiveComponent:"Aeronautical Beacon",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Aeronautical Beacon",value:"Missing",text:"Missing"
             },{
                 defectiveComponent:"Pipe",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Tower Structure",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Tower Structure",value:"Crack",text:"Crack"
             },{
                 defectiveComponent:"Tower Structure",value:"Paint Damaged",text:"Paint Damaged"
             },{
                 defectiveComponent:"Foundations",value:"Crack",text:"Crack"
             },{
                 defectiveComponent:"Welds Between the Elements of the Structure",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Welds Between the Elements of the Structure",value:"Crack",text:"Crack"
             },{
                 defectiveComponent:"Beams",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Beams",value:"Crack",text:"Crack"
             },{
                 defectiveComponent:"Beams",value:"Broken",text:"Broken"
             },{
                 defectiveComponent:"Anchors joints with Tower Structure",value:"Rusty",text:"Rusty"
             },{
                 defectiveComponent:"Anchors joints with Tower Structure",value:"Crack",text:"Crack"
             },{
                 defectiveComponent:"Anchors joints with Tower Structure",value:"Broken",text:"Broken"
             }

           ] .filter(defect => defect.defectiveComponent == dropdownValuestate.defective_component)
           let arr =  [  {
            defectiveStructure:"Select",value:"Select",text:"Select"
        },...filteredArray].map(defect=>{
            let temp =  {label:defect.text,value:defect.value}
            return temp
        })
               return  arr;
       
    }
  
    //----------------------------------------------------------------------------------------
    /* Return the generated HTML to react for Render.
    */ 
  return (<>
          {showModal && <ModalDialog height={450} width={650} title={`Set Pin Annotations`} onClose={() => closingOps()}>
              {/*-------------------------Pin Annotation type selector--------------------------------*/}
              <div id="optionIdType">
                  <div style={{ /*marginBottom: 10, marginLeft: -10*/}}>
                      <select  onClick={optionHtmlMarkup} id="optionId" name="annotationOption" className="annotationOption" value = {typeOfAnnotation} onChange={(e)=>setTypeOfAnnotation(e.target.value)}>
                        <option disabled value="Options"> Options </option>
                        <option value="Tag"> Tag </option>
                        <option value="Defect" disabled = {ConfigManager.INsiteImageUrl.length>0 ? false:true}> Defect </option>
                      </select>
                  </div>
              </div>
                {/*-----------------thise element will show an hide based on button events ---------*/}
              <div className="imodel-viewport-dialog-container" id= "pin-tag-modal">
              <div id="projectshare-modal-container" style={{ margin: "auto", overflow: "auto", display: "none", height: "inherit" }}>
                  <div style={{ fontWeight: 700}}>
                          Project Explorer
                      </div>
                      <div style={{ marginBottom: 10, marginLeft: 155, marginRight: 5, marginTop: -15 }} title="Open root project folder, use this to open and navigate back from the top.">
                          <button id="pshare-back-button" onClick={async() =>{
                               let data = await getParentFolderData(ConfigManager.projectId!);
                               projectShareRecursions (data)
                          } }></button>
                      </div>
                      <div style={{ marginBottom: 10, marginLeft: 260, marginTop: "-22px"}} title="Exit this page">
                          <button style={{ borderWidth: "thin"}} onClick={() => exitProjectShareModal()}>Exit</button>
                      </div>
                  </div>
                  {/*---------*/}
                  <div id="projectshare-imagemodal-container" style={{ margin: "auto", overflow: "auto", display: "none" }}>
                  <div style={{ fontWeight: 700 }}>
                          Images Search
                      </div>
                      <div style={{ marginBottom: 10, marginLeft: 155, marginRight: 5, marginTop: -13 }} title="Search All ImageFiles in Folder[.">
                      {/*<button id= "pshare-back-button" style={{ borderWidth: "thin", borderRadius: "2px 5px" }} onClick={() => projectShareShowAllImages()}></button>*/}
                      </div>
                  <div style={{ marginBottom: 10, marginLeft: 267, marginTop: "-22px" }} title="Exit this page">
                          <button style={{ borderWidth: "thin", marginTop: 2 }} onClick={() => exitProjectShareModal()}>Exit</button>
                      </div>
                  </div>
                    {/*--------------------------Issue comments-------------------------------*/}
                <div id="annotation-modal-container" style={{ margin: "auto"}}>
                    {/*---------*/}
                    <div style={{ marginBottom: 2 }}>
                      <div className="disc-text" id="sub-title">
                        Subject :
                      </div>
                        <form>
                            <input className="renderer-text-box" id="sub-text" type="text" value={tagTitle} onChange={tagTitleF} />
                        </form>
                    </div>
                  {/*------------*/}
                  <div>
                      <div className="disc-text" id="comment-title">
                          Description:
                      </div>
                      <div id="comment-text-div">
                          <form title = "Add a Comment and Drag-Drop your Images to Upload">
                              <textarea className="renderer-text-box renderer-text-area" id="comment-text" rows={5} cols={30} name="comment" onDrop={onImageDrop} onDragOver={onImageDragOver} onClick={onClickComment } onChange={issueCommentF} value={tagComment} ></textarea>
                          </form>
                      </div>
                      {/*------------------------------Project Share Options---------------------------*/}               
                      <div id="projShareOptions">
                          <div style={{ marginBottom: 5, marginLeft: 165, marginTop: 2, borderWidth: "thin" }}>
                              <button title="Link Project Files" className="icon icon-attach" id="link-proj-files" onClick={() => linkProjectFilesOnClick()}></button>
                              <button title="Link Project Images" className="icon icon-image" id="link-proj-img" onClick={() => loadAndShowImagesLinkModal()}></button>
                              <button title="Add External Link" className="icon icon-link" id="link-proj-ext" onClick={openExternalLinkPopup}></button>
                          </div>
                      </div>
                      <br></br>
                      <div id="holder" >{/*<p className="dropimage_text">Drop Images</p>*/}</div>
                  </div>
                  {/*---------------------------------------------------------*/}
                  <div className="float-div">
                      <hr style={{ marginTop: 0, overflow: "hidden" }} className="img-border" />
                      <div style={{ marginTop: 20, marginBottom: 0, marginLeft: "13px" }} className="btn-wrapper">
                          <button className="save-button" id="save-button" onClick={saveTag}> Save </button>
                          <button className="cancel-button" onClick={closingOps}> Cancel </button>
                    </div>
                  </div>
              </div>
                {/*--------------------------Defect Options-------------------------------*/}
                <div id="defect-modal-container">
                      {/*---------------------------------------------------------*/}

                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '47%',marginLeft:"10px" }}>

                      <LabeledSelect
                  className="defectStruct"
                  label='Defective Structure :'
                  displayStyle="inline"
                  value={dropdownValuestate.defectiveStructure}
                  setFocus={true}
                  options={ [{label:"Defective Structure type",value:"Defective Structure type",disabled:true},
                  {label:"Shelter",value:"Shelter"},
                  {label:"Site Area",value:"Site Area"},
                  {label:"Tower",value:"Tower"},
                  
                  ]}
                  onChange={e=>handleSelectValue(e,"defectiveStructure")}
                />
                     <LabeledSelect
                  className="defectStruct"
                  label='Defect :'
                  displayStyle="inline"
                  // status="positive"
                  value={dropdownValuestate.defectType_Pin}
                  setFocus={true}
                  style={{display:"flex"}}
                  options={ selectDefect()}
                  onChange={e=>handleSelectValue(e,"defectType_Pin")}
                />
                    <LabeledInput
                     className="defectStruct"
                        label="Owner :"
                        size="small"
                        type="text"
                        step={0.1}
                        value={ownerName}
                        displayStyle="inline"
                        onChange={(e)=>updateOwnerName(e)}
                      />
                    <LabeledInput
                     className="defectStruct"
                        label="Height :"
                        size="small"
                        type="number"
                        step={0.1}
                        value={height}
                        displayStyle="inline"
                        onChange={(e)=>updateHeight(e)}
                        
                      />
                           <LabeledInput
                            className="defectStruct"
                        label="Installation Date :"
                        size="small"
                        type="datetime-local"
                        value={installationDateTime}
                        displayStyle="inline"
                        onChange={(e)=>updateInstallationDateTime(e)}
                      />
                   
                   
          </div>

          <div style={{ width: '52%'}}>

                      <LabeledSelect
                  className="defectStruct"
                  label='Defective Component :'
                  displayStyle="inline"
                  // status="positive"
                  value={dropdownValuestate.defective_component}
                  setFocus={true}
                  options={ selectDefectiveComponent()}
                  onChange={e=>handleSelectValue(e,"defective_component")}
                />
                       <LabeledSelect
                  className="defectStruct"
                  label='Criticality :'
                  displayStyle="inline"
                  value={dropdownValuestate.crtically}
                  setFocus={true}
                  options={ [
                  {label:"Minor",value:"Minor"},
                  {label:"Medium",value:"Medium"},
                  {label:"Major",value:"Major"},
                  
                  ]}
                  onChange={e=>handleSelectValue(e,"crtically")}
                />
             
                           
                           <LabeledInput
                            className="defectStruct"
                        label="Inspector Name :"
                        size="small"
                        type="text"
                       
                        value={inspectorName}
                        displayStyle="inline"
                        onChange={(e)=>updateInspectorName(e)}
                      />
                         
                    
                         <LabeledInput
                          className="defectStruct"
                        label="Length :"
                        size="small"
                        type="number"
                        step={0.1}
                        value={length}
                        displayStyle="inline"
                        onChange={(e)=>updateLength(e)}
                      />
                          <LabeledInput
                           className="defectStruct"
                        label="Inspection Date :"
                        size="small"
                        type="datetime-local"
                        value={inspectionDateTime}
                        displayStyle="inline"
                        onChange={(e)=>updateInspectionDateTime(e)}
                      />
</div>
                      </div>
                      
                  
                   
              
                    
                      {/*---------------------------------------------------------*/}
                       <hr style={{marginTop: 30, overflow: "hidden"}} className="img-border" />                                
                       <div style={{marginTop:20, marginBottom:0}} className="btn-wrapper">
                          <button style={{ width: "100px" }} disabled = {checkDisabled()} className={checkDisabled()?"property-button-disabled":"push-btn"} onClick={saveDefect} >Save</button>
                       </div>
                </div>
              </div>
          </ModalDialog>
        }
    </>
   );
 /*---------------------------------------------------------*/
};